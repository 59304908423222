import useCustomer from 'hooks/customer/useCustomer';
import { useEffect, useState } from 'react';
import { trackEvents, trackInWindow } from 'hooks/tracking/track';
import toItem from 'hooks/tracking/to-item';
import { useRouter } from 'next/router';
import useLocalWishlist from './useLocalWishlist';
import useCustomerWishlist from './useCustomerWishlist';
import useWishlistProducts from './useWishlistProducts';

const useWishlist = () => {
  const { customer: { isLoggedIn } = { isLoggedIn: false }, isLoading: customerIsLoading } =
    useCustomer();

  const { push } = useRouter();

  const customerWishlist = useCustomerWishlist(isLoggedIn);
  const localWishlist = useLocalWishlist();

  const [loadingProductId, setLoadingProductId] = useState();

  const wishlist = isLoggedIn ? customerWishlist : localWishlist;
  const { count, add, remove, productIds, isLoading: wishlistIsLoading } = wishlist;

  const {
    isLoading: productsIsLoading,
    products,
    mutate: mutateProducts,
  } = useWishlistProducts(productIds, {
    // Remove deleted and invalid products
    onSuccess: async newProducts =>
      remove(productIds.filter(productId => !newProducts.find(({ id }) => productId === id))),
    onError: e => console.error(e),
  });

  const isLoading = customerIsLoading || wishlistIsLoading || productsIsLoading;

  useEffect(() => {
    const saveLocalWishlistToCustomer = async () => {
      await customerWishlist.add(localWishlist.productIds);
      localWishlist.clear();
    };

    if (isLoggedIn && localWishlist.productIds.length) saveLocalWishlistToCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, localWishlist.productIds]);

  const isIn = id => wishlist.productIds.includes(id);

  return {
    count,
    products,
    toggle: async product => {
      const { id: productId } = product;

      // New Flow 4/12/24: Guest should not be able to add wishlist. Instead, redirects them to log in page as per client's request.
      if (!isLoggedIn) {
        push('/account/login/');
        return;
      }

      if (loadingProductId) return;

      setLoadingProductId(productId);

      const isInWishlist = isIn(productId);

      if (isInWishlist) {
        await remove([productId]);
        mutateProducts(products.filter(({ id }) => id !== productId));
      } else {
        await add([productId]);
        mutateProducts([product, ...products]);
      }

      const trackEvent = isInWishlist ? trackEvents.removeFromWishlist : trackEvents.addToWishlist;
      const variant = product.variants.edges[0].node;
      const price = Number(variant.priceV2.amount);
      trackInWindow({
        event: trackEvent,
        page_path: window.location.pathname,
        page_title: window.document.title,
        currency: variant.priceV2.currencyCode,
        value: price,
        items: [toItem(product)],
      });

      setLoadingProductId();
    },
    isIn,
    isLoading,
    isLoadingProduct: productId => loadingProductId === productId,
  };
};

export default useWishlist;
